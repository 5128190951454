import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import styled from "styled-components";
import { useTranslation } from "react-i18next"; // Import useTranslation
import "../i18n"; // Import i18n configuration

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  font-family: "Roboto", sans-serif;
`;

const Header = styled.div`
  width: 100%;
  background: #719e19;
  padding: 20px;
  color: white;
  text-align: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);
  margin-bottom: 30px;
`;

const OTPContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
`;

const OTPInput = styled.input`
  width: 70px;
  height: 70px;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  border-radius: 20px;
  border: 2px solid #719e19;
  color: #719e19;
  margin: 0 5px;
  outline: none;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #0056b3;
  }
`;

const ResendButton = styled.button`
  background: none;
  border: none;
  color: #719e19;
  cursor: pointer;
  font-size: 16px;
  transition: color 0.3s ease;
  &:hover {
    color: #0056b3;
  }
`;

const VerifyButton = styled.button`
  background-color: #719e19;
  border: none;
  border-radius: 5px;
  color: #fff;
  padding: 12px 25px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  &:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

const OTPImage = styled.img`
  max-width: 325px;
  margin: 20px 0;
`;

const MobileOTP = () => {
  const { t } = useTranslation(); // Initialize useTranslation
  const navigate = useNavigate();
  const location = useLocation();
  const [mobileNumber, setMobileNumber] = useState(null);
  const [userPName, setUserName] = useState(null);
  const [loadingMore, setResend] = useState(false);
  const [verifying, setVerify] = useState(false);
  const [otpNumbers, setOtpNumbers] = useState(["", "", "", ""]);
  const inputsRef = useRef([]);
  const [timer, setTimer] = useState(0);

  const session = JSON.parse(sessionStorage.getItem("userData"));
  const sessionUserId = session?.Id || null;

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer]);

  useEffect(() => {
    if (session && session.userMsisdn) {
      setMobileNumber(session.userMsisdn);
    } else {
      console.error("Mobile number is not available session.");
    }
  }, [session]);

  const onDidntReceiveTheClick = useCallback(async () => {
    try {
      setResend(true);
      const response = await fetch(
        "https://bankalfalah.webddocsystems.com/afghan.php/sendOtp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            msisdn: mobileNumber,
            priceListTag: "d",
            serviceTag: "WEBDOC_DH",
            autoRenew: "true",
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      if (responseData.code != 0) {
        Swal.fire({
          icon: "warning",
          title: t("enterOTP"),
          text: responseData.message,
          confirmButtonText: "OK",
        });
      } else {
        setTimer(60);
      }
    } catch (error) {
      console.error("Error resending OTP:", error);
    } finally {
      setResend(false);
    }
  }, [mobileNumber, t]);

  function delay(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const onVerifyTextClick = useCallback(async () => {
    try {
      setVerify(true);
      const otp = otpNumbers.join("");
      const response = await fetch(
        "https://bankalfalah.webddocsystems.com/afghan.php/verifyOtp",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            msisdn: mobileNumber,
            otp: otp,
            serviceTag: "WEBDOC_DH",
            // forceFreeTrial: "true",
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      if (responseData.code != 0) {
        if (responseData.message === "Subscription Already Exist") {
          await handleSubscribe();
        } else {
          Swal.fire({
            icon: "error",
            text: responseData.message,
            // title: t("verificationFailed"),
            // text: t("verificationFailed"),
          });
        }
      } else {
        await delay(7000);

        // Call your function after the delay
        await checkLastInsertedLogStatus();

        // await handleSubscribe();

        // const user = JSON.parse(sessionStorage.getItem("userData")) || {};
        // const currentDate = new Date();
        // const expiryDate = new Date();
        // expiryDate.setDate(expiryDate.getDate() + 1);
        // user.featuresPackage = true;
        // user.featurePackageDetails.activeDate = formatDate(
        //   currentDate.toISOString()
        // );
        // user.featurePackageDetails.nextChargeDare = formatDate(
        //   expiryDate.toISOString()
        // );
        // sessionStorage.setItem("userData", JSON.stringify(user));
        // Swal.fire({
        //   title: t("subscribed"),
        //   text: t("feature_subscription_success"),
        //   icon: "success",
        //   confirmButtonText: "OK",
        //   allowOutsideClick: false,
        // }).then(() => {
        //   navigate(`/dashboard`);
        // });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire({
        icon: "error",
        // title: t("verificationFailed"),
        text: t("verificationFailed"),
      });
    } finally {
      setVerify(false);
    }
  }, [mobileNumber, otpNumbers, t]);

  const checkLastInsertedLogStatus = useCallback(async () => {
    try {
      setVerify(true);
      const otp = otpNumbers.join("");
      const response = await fetch(
        "https://bankalfalah.webddocsystems.com/afghan.php/get-record",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            msisdn: mobileNumber,
          }),
        }
      );
      const responseData = await response.json();
      if (!response.ok) {
        throw new Error(responseData.message);
      }
      if (responseData.status == "success") {
        if (
          responseData.record.status == 1 &&
          responseData.record.errorCode == 0
        ) {
          await handleSubscribe();
        } else {
          if (
            responseData.record.status == 0 &&
            responseData.record.errorCode == 109
          ) {
            Swal.fire({
              icon: "error",
              text: "Insufficient balance to subscription",
            });
          } else {
            Swal.fire({
              icon: "error",
              // title: t("verificationFailed"),
              text: t("verificationFailed"),
            });
          }
        }
      } else {
        Swal.fire({
          icon: "error",
          text: responseData.message,
          // title: t("verificationFailed"),
          // text: t("verificationFailed"),
        });
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      Swal.fire({
        icon: "error",
        // title: t("verificationFailed"),
        text: t("verificationFailed"),
      });
    }
  });
  const handleSubscribe = useCallback(async () => {
    try {
      const user = JSON.parse(sessionStorage.getItem("userData")) || {};
      const sessionUserId = user.applicationUserId;

      const response = await axios.post(
        "https://bundleapi.webddocsystems.com/api/Account/ActivateFeatureSusbcription",
        null,
        {
          params: {
            patientId: sessionUserId,
            productid: 1,
          },
        }
      );

      if (response.status === 200 && response.data.responseCode === "0000") {
        const currentDate = new Date();
        const expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 1);
        user.featuresPackage = true;
        user.featurePackageDetails.activeDate = formatDate(
          currentDate.toISOString()
        );
        user.featurePackageDetails.nextChargeDare = formatDate(
          expiryDate.toISOString()
        );
        sessionStorage.setItem("userData", JSON.stringify(user));

        Swal.fire({
          title: t("subscribed"),
          text: t("feature_subscription_success"),
          icon: "success",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then(() => {
          navigate(`/dashboard`);
        });
      } else {
        Swal.fire({
          title: t("subscription_failed"),
          // text: t("something_went_wrong"),
          icon: "error",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error activating subscription:", error);
    }
  });

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    const updatedOtpNumbers = [...otpNumbers];
    if (/^\d*$/.test(value)) {
      updatedOtpNumbers[index] = value;
      setOtpNumbers(updatedOtpNumbers);
    }
    if (index < otpNumbers.length - 1 && value.length === 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleBackspace = (index, e) => {
    if (e.keyCode === 8 && index > 0 && !otpNumbers[index]) {
      const updatedOtpNumbers = [...otpNumbers];
      updatedOtpNumbers[index - 1] = "";
      setOtpNumbers(updatedOtpNumbers);
      inputsRef.current[index - 1]?.focus();
    }
  };

  // Check if all OTP fields are filled
  const isAllOtpFilled = otpNumbers.every((num) => num !== "");

  return (
    <Container>
      <Header>
        <h1>{t("enterOTP")}</h1>
      </Header>
      <div className="text-center mb-4">
        <OTPImage src="/assets/enterotp-1@2x.png" alt={t("otpImageAlt")} />
      </div>
      <div className="text-center mb-4">
        <h4>{t("otpSentMessage")}</h4>
        <p className="text-muted">{t("otpSentToNumber", { mobileNumber })}</p>
      </div>
      <OTPContainer>
        {otpNumbers.map((otp, index) => (
          <OTPInput
            key={index}
            ref={(ref) => (inputsRef.current[index] = ref)}
            value={otp}
            onChange={(e) => handleInputChange(e, index)}
            onKeyDown={(e) => handleBackspace(index, e)}
            maxLength="1"
            type="tel"
          />
        ))}
      </OTPContainer>

      <div className="text-center mb-4">
        <ResendButton
          onClick={onDidntReceiveTheClick}
          disabled={loadingMore || timer > 0}
        >
          {loadingMore ? t("resendingButton") : t("resendButton")}
          {timer > 0 && ` (${timer})`}
        </ResendButton>
      </div>

      <div className="text-center">
        <VerifyButton
          onClick={verifying || !isAllOtpFilled ? null : onVerifyTextClick}
          disabled={verifying || !isAllOtpFilled}
        >
          {verifying ? t("verifyingButton") : t("verifyButton")}
        </VerifyButton>
      </div>
    </Container>
  );
};

export default MobileOTP;
